<template>
  <validation-provider
    :name="$t(veeName)"
    :rules="veeRules"
    v-slot="validationContext"
  >
    <b-form-group
      label-class="font-weight-bold"
      :label="$t(label)"
      :label-for="veeName"
    >
      <b-form-select
        :id="veeName"
        v-bind="$attrs"
        v-on="$listeners"
        :state="getValidationState(validationContext)"
      >
        <slot name="default"></slot>
      </b-form-select>
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    veeName: {
      type: String,
      default: ""
    },
    veeRules: {
      type: [String, Object],
      default: null
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid, touched }) {
      if (dirty || validated) return valid ? null : valid;
      else return null;
    }
  }
};
</script>

<style></style>

<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.communication.title") }}</h3>
    <communication-filters></communication-filters>
    <b-button
      class="float-right mb-3"
      size="md"
      variant="secondary"
      v-b-modal.select-condominium-modal
    >
      {{ $t("page.communication.newCommunication") }}
    </b-button>
    <select-condominium :condominiums="condominiums"></select-condominium>
    <communication-table
      :communications="formatedCommunications"
      :fields="fields"
      :sortBy.sync="sortBy"
      :sortDesc.sync="sortDesc"
      :retrieveDataCallback="retrieveCommunicationsCallback"
    />
  </div>
</template>

<script>
import { EventBus } from "./../eventBus.js";
import { appMixin } from "./../mixins";

import SelectCondominium from "../components/Communication/SelectCondominium";
import CommunicationTable from "../components/Condominium/CommunicationTable";
import CommunicationFilters from "../components/Communication/CommunicationFilters";

export default {
  components: {
    SelectCondominium,
    CommunicationTable,
    CommunicationFilters
  },
  mixins: [appMixin],
  data() {
    return {
      dataLoaded: false,
      sortBy: "createdAt",
      sortDesc: true,
      currentCommunication: null,
      preview: false,
      condominiums: []
    };
  },
  mounted() {
    EventBus.$on("eventBusReloadCommunications", this.fetchData);
    this.$store
      .dispatch("condominium/retrieveCondominiums")
      .then(() => {
        this.condominiums = this.$store.getters[
          "condominium/getActiveCondominiums"
        ];
      })
      .then(() => {
        this.$store
          .dispatch("communication/retrieveUserCommunications")
          .then(() => {
            this.dataLoaded = true;
          });
      });
  },
  computed: {
    communications() {
      return this.$store.getters["communication/getUserCommunications"];
    },
    formatedCommunications() {
      return this.dataLoaded && this.communications.length > 0
        ? this.communications
        : [];
    },
    fields() {
      const initFields = [
        { key: "subject", label: "subject", sort: true },
        { key: "createdAt", label: "date", sort: true },
        { key: "body", label: "description", sort: true },
        { key: "readcounter", label: "readcounter", sort: false },
        { key: "documents", label: "documents" },
        { key: "attachments", label: "attachments" },
        // { key: "download", label: "download" },
        { key: "status", label: "status" },
        { key: "details", label: "" }
      ];
      if (!this.$is1Board)
        initFields.splice(6, 0, { key: "download", label: "download" });
      return initFields;
    }
  },
  methods: {
    async fetchData() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("condominium/retrieveCondominiums");
        this.condominiums = await this.$store.getters[
          "condominium/getActiveCondominiums"
        ];
        await this.$store.dispatch("communication/retrieveUserCommunications");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    retrieveCommunicationsCallback() {
      return this.$store.dispatch("communication/retrieveUserCommunications");
    }
  },
  beforeDestroy() {
    EventBus.$off("eventBusReloadCommunications");
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <b-form-group label-class="font-weight-bold" :label="$t(label)">
    <b-input-group>
      <b-form-input
        v-bind="$attrs"
        v-on="$listeners"
        ref="searchInput"
        class="search-input"
        @keyup.native.enter="onEnter"
      >
      </b-form-input>
      <b-icon
        v-if="hasSearchText"
        icon="x-circle"
        class="clickable clear-btn"
        variant="black"
        @click="clearInput"
      ></b-icon>
      <b-input-group-append is-text>
        <b-icon
          icon="search"
          class="clickable"
          variant="black"
          @click="search"
        ></b-icon>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  components: {},
  props: {
    label: {
      type: String,
      default: ""
    }
  },
  computed: {
    hasSearchText() {
      return this.$attrs.value.length > 0;
    }
  },
  methods: {
    clearInput() {
      this.$listeners.input("");
      this.$refs.searchInput.$el.focus();
      this.search();
    },
    onEnter() {
      this.$refs.searchInput.$el.blur();
      this.search();
    },
    search() {
      this.$emit("search");
    }
  }
};
</script>

<style lang="scss" scoped>
.search-input {
  padding-right: 30px;
}
.clear-btn {
  position: absolute;
  top: 12px;
  right: 54px;
  z-index: 3;
}
</style>

<template>
  <div>
    <b-modal
      id="select-condominium-modal"
      :ok-disabled="!selection"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <template v-slot:modal-title>
        <h5 class="mb-0 text-secondary">
          {{ $t("page.communication.select.title") }}
        </h5>
      </template>
      <template v-slot:default>
        <form ref="condominiumselectform" @submit.stop.prevent="handleSubmit">
          <b-form-select
            v-model="selection"
            :options="options"
            required
            size="sm"
            class="mt-3"
          ></b-form-select>
        </form>
      </template>
      <template v-slot:modal-ok>
        {{ $t("button.confirm") }}
      </template>
      <template v-slot:modal-cancel>
        {{ $t("button.cancel") }}
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    condominiums: {
      type: Array
    }
  },
  data() {
    return {
      selection: ""
    };
  },
  computed: {
    options() {
      var data = [{ value: null, text: this.$t("selectAnOption") }];
      this.condominiums.forEach(condominium => {
        var condoOBJ = {};
        condoOBJ.value = condominium._id;
        condoOBJ.text = condominium.name;
        data.push(condoOBJ);
      });
      return data;
    }
  },
  methods: {
    resetModal() {
      this.selection = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.$store.commit("communication/RESET");
      await this.$store.dispatch(
        "condominium/retrieveCondominium",
        this.selection
      );
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
        this.$router.push({ name: "NewCommunication" });
      });
    },
    checkFormValidity() {
      const valid = this.$refs.condominiumselectform.checkValidity();
      return valid;
    }
  }
};
</script>

<style lang="scss" scoped></style>
